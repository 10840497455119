<template>
  <footer id="default-app-footer">
    <div v-if="isHomePage" class="w-full py-16 footer-background">
      <div class="container">
        <div class="md:w-2/3 xl:w-1/2 ml-auto">
          <p
            class="bg-charcoal-07 p-8 lg:p-10 xl:px-12 text-white text-base lg:text-lg xl:text-xl opacity-75"
          >
            {{ footerBodyDescription }}
          </p>
        </div>
      </div>
    </div>
    <div class="border-brand-apricot-200 border-b-5" />
    <div
      class="flex items-center w-full py-4 bg-brand-grey-700 transition-all duration-500"
    >
      <nav class="container" aria-label="Main Footer">
        <div
          class="flex-col sm:flex sm:flex-row justify-between items-center border-b border-brand-grey-500 pb-6 my-6 transition-all duration-500"
        >
          <nuxt-link :to="{ name: 'index' }">
            <img
              src="/TAFE-logo.svg"
              class="h-8 transition-all duration-500"
              alt="The ��ɫֱ��logo"
            />
          </nuxt-link>

          <ul class="flex justify-between text-white mt-8 sm:mt-0">
            <li class="mr-8 flex items-center">
              <a href="tel:+612131601" class="flex items-center">
                <icon name="IconPhone" class="text-2xl mr-1" />
                131 601
              </a>
            </li>
            <li class="flex items-center">
              <a
                href="https://tafensw.custhelp.com/app/contact-us/enquire?campaign=e2e"
                target="_blank"
                rel="noopener noreferrer"
                class="flex items-center"
              >
                <icon name="IconChat" class="text-2xl mr-1" />
                Contact us
              </a>
            </li>
          </ul>
        </div>

        <div class="grid xl:grid-cols-4 gap-8 text-white">
          <div class="xl:col-span-3">
            <div class="reading-columns">
              <h3 class="text-xl mb-4">Links</h3>
              <ul class="mb-4">
                <li
                  v-for="(link, i) in footerPrimaryLinks"
                  :key="i"
                  :class="[
                    i !== footerPrimaryLinks.length - 1 ? 'mb-3' : 'mb-0'
                  ]"
                >
                  <tafe-link :item="link" />
                </li>
              </ul>
              <h3 class="text-xl mt-8 mb-4">Logins</h3>
              <ul>
                <li
                  v-for="(link, i) in footerSecondaryLinks"
                  :key="i"
                  :class="[
                    i !== footerSecondaryLinks.length - 1 ? 'mb-3' : 'mb-0'
                  ]"
                >
                  <tafe-link :item="link" />
                </li>
              </ul>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-8">
            <app-footer-newsletter class="" />

            <div
              v-if="footerBodySubDescription"
              v-html="$md.render(footerBodySubDescription)"
            ></div>

            <div class="text-center sm:text-left xl:row-start-2">
              <h3 class="h5 mb-4">Connect with us</h3>
              <ul class="flex gap-2 justify-center sm:justify-start">
                <li
                  v-for="(social, i) in socialLinks"
                  :key="i"
                  class="list-inline-item"
                >
                  <a
                    :href="social.href"
                    target="_blank"
                    class="text-lg flex items-center justify-center bg-white text-black w-8 h-8 rounded-full hover:bg-black hover:text-white"
                    ><Icon :name="social.icon" /><span class="sr-only">{{
                      social.title
                    }}</span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="!isHomePage"
          class="cultural-graphic bg-white text-black relative mt-6 mb-4"
        >
          <img
            src="/acknowledgement-top.png"
            alt=""
            class="w-full h-4 object-cover object-left relative"
          />
          <p class="border-l-8 border-brand-apricot-400 p-4 max-w-full">
            {{ footerBodyDescription }}
          </p>
        </div>
      </nav>
    </div>
    <div class="w-full py-3 bg-black text-white">
      <div class="container flex justify-between">
        <ul
          v-if="footerBottomLinks && footerBottomLinks.length > 0"
          class="flex gap-4"
        >
          <li v-for="(item, i) in footerBottomLinks" :key="i" class="">
            <a
              v-if="item.Link"
              :href="item.Link.url"
              target="_blank"
              class="underline hover:no-underline"
              rel="noopener noreferrer"
              >{{ item.Link ? item.Link.text : '' }}</a
            >
          </li>
        </ul>
        <p>{{ footerCopyright }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'pinia'
import { useSiteConfigStore } from '~/stores/siteConfig'

const INTERNAL_HOSTNAME = '/'

export default {
  data: () => ({
    socialLinks: [
      {
        title: 'Like us on Facebook',
        href: '//www.facebook.com/tafensw',
        icon: 'IconFacebook'
      },
      {
        title: 'Find us on LinkedIn',
        href: '//www.linkedin.com/company/tafe-nsw',
        icon: 'IconLinkedin'
      },
      {
        title: 'Follow us on YouTube',
        href: '//www.youtube.com/tafe',
        icon: 'IconYoutube'
      },
      {
        title: 'Follow us on X',
        href: '//x.com/tafensw',
        icon: 'IconX'
      },
      {
        title: 'Follow us on Instagram',
        href: 'https://instagram.com/tafensw/',
        icon: 'IconInstagram'
      }
    ]
  }),
  computed: {
    ...mapState(useSiteConfigStore, {
      footerData: (store) => store.config?.footer
    }),
    isHomePage() {
      return this.$route.path === '/'
    },
    footerPrimaryLinks() {
      return this.footerData?.BodyLinks?.targetItems?.map((item) => ({
        title: item.Link?.text,
        href: item.Link?.url,
        external: !this.isInternalLink(item.Link?.url, INTERNAL_HOSTNAME)
      }))
    },
    footerSecondaryLinks() {
      return this.footerData?.LoginLinks?.targetItems?.map((item) => ({
        title: item.Link?.text,
        href: item.Link?.url,
        external: true
      }))
    },
    footerBodyDescription() {
      return this.footerData?.BodyDescription?.value
        .replace('\r\n', '')
        .replace(/\s+/g, ' ')
    },
    footerCopyright() {
      return this.footerData?.FooterDescription?.value
    },
    footerBottomLinks() {
      return this.footerData?.FooterLinks?.targetItems
    },
    footerBodySubDescription() {
      return this.footerData?.BodySubDescription.value
    }
  },
  methods: {
    currentYear() {
      return new Date().getFullYear()
    },
    isInternalLink(url, baseUrl) {
      try {
        const base = new URL(baseUrl)
        return new URL(url, base).hostname === base.hostname
      } catch (err) {
        console.debug('errors:', err)
      }
      return false
    }
  }
}
</script>

<style lang="postcss" scoped>
.cultural-graphic {
  @apply relative;
  border-bottom: 16px solid #fcaa00;
}

.cultural-graphic::after {
  @apply bg-brand-grey-700 absolute block;
  content: '';
  bottom: -16px;
  right: -1px;
  left: 4rem;
  height: 9px;
}

@media (min-width: 768px) {
  .reading-columns {
    columns: 2;
    column-gap: 2rem;
  }
}

@media (min-width: 1024px) {
  .reading-columns {
    columns: 3;
  }
}

.footer-background {
  background-image: url('/footer-acknowledgement.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
